import { ClienteAcceso } from "./ClienteAcceso"
export class Cliente{
  id:number
  razon_social:string = ""
  direccion:string = ""
  direccion_fiscal:string = ""
  regimen_fiscal:string = ""
  permiso_comision_regulatoria_energia:string = ""
  telefono:string = ""
  email:string = ""
  representante_legal:string = ""
  responsable_actividad:string = ""
  rfc:string = ""
  fecha_creacion:string
  fecha_actualizacion:string
  eliminado:boolean
  acceso:ClienteAcceso = new ClienteAcceso()
}
