import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Authenticate } from 'src/app/models/Authenticate';
@Injectable({
  providedIn: 'root'
})
export class SolicitudService {
  url:string = environment.url
  constructor(private http: HttpClient) { }

  findAll(formData:FormData):Observable<any>{
    const params = this.formDataToHttpParams(formData);
    return this.http.get(`${this.url}/solicitud/v1/findAll`,{ params });
  }

  findById(id:number):Observable<any>{
    return this.http.get(`${this.url}/solicitud/v1/findById/${id}`);
  }

  findByFolio(folio:string):Observable<any>{
    return this.http.get(`${this.url}/solicitud/v1/findByFolio/${folio}`);
  }

  solicitar(data:any):Observable<any>{
    return this.http.post(`${this.url}/solicitud/v1/solicitar`, data);
  }

  cotizacion(formData:FormData):Observable<any>{
    return this.http.put(`${this.url}/solicitud/v1/cotizacion`, formData);
  }


  downloadDocumentoTemplate(id:number): Observable<any>{
    return this.http.get(`${this.url}/solicitud/v1/downloadDocumentoTemplate/${id}`, {
      responseType: 'arraybuffer',
    })
  }

  uploadDocumentoTemplateCliente(formData:FormData){
    return this.http.put(`${this.url}/solicitud/v1/uploadDocumentoTemplateCliente`,formData)
  }

  downloadDocumentoTemplateCliente(id:number): Observable<any>{
    return this.http.get(`${this.url}/solicitud/v1/downloadDocumentoTemplateCliente/${id}`, {
      responseType: 'arraybuffer',
    })
  }

  uploadDocumentoSolicitado(formData:FormData){
    return this.http.put(`${this.url}/solicitud/v1/uploadDocumentoSolicitado`,formData)
  }

  downloadDocumentoSolicitado(id:number): Observable<any>{
    return this.http.get(`${this.url}/solicitud/v1/downloadDocumentoSolicitado/${id}`, {
      responseType: 'arraybuffer',
    })
  }

  uploadFichaPago(formData:FormData){
    return this.http.put(`${this.url}/solicitud/v1/uploadFichaPago`,formData)
  }

  downloadFichaPago(id:number): Observable<any>{
    return this.http.get(`${this.url}/solicitud/v1/downloadFichaPago/${id}`, {
      responseType: 'arraybuffer',
    })
  }

  findAllSecciones():Observable<any>{
    return this.http.get(`${this.url}/solicitud/v1/findAllSecciones`)
  }

  downloadCotizacion(id:number): Observable<any>{
    return this.http.get(`${this.url}/solicitud/v1/downloadCotizacion/${id}`, {
      responseType: 'arraybuffer',
    })
  }

  uploadCotizacionFirmada(formData:FormData){
    return this.http.put(`${this.url}/solicitud/v1/uploadCotizacionFirmado`,formData)
  }

  downloadCotizacionFirmada(id:number): Observable<any>{
    return this.http.get(`${this.url}/solicitud/v1/downloadCotizacionFirmado/${id}`, {
      responseType: 'arraybuffer',
    })
  }

  downloadFactura(id:number): Observable<any>{
    return this.http.get(`${this.url}/solicitud/v1/downloadFactura/${id}`, {
      responseType: 'arraybuffer',
    })
  }

  findAllActividad(folio:string): Observable<any>{
    return this.http.get(`${this.url}/solicitud/v1/findAllActividad/${folio}`);
  }

  findActividad(id:number): Observable<any>{
    return this.http.get(`${this.url}/solicitud/v1/findActividad/${id}`);
  }

  subirComprobanteActividad(formData:FormData){
    return this.http.put(`${this.url}/solicitud/v1/subirComprobanteActividad`, formData)
  }

  downloadDocumentoFinalizacion(id:number): Observable<any>{
    return this.http.get(`${this.url}/solicitud/v1/downloadDocumentoFinalizacion/${id}`, {
      responseType: 'arraybuffer',
    })
  }

  downloadFacturaMensual(id:number): Observable<any>{
    return this.http.get(`${this.url}/solicitud/v1/downloadFacturaMensual/${id}`, {
      responseType: 'arraybuffer',
    })
  }

  private formDataToHttpParams(formData: FormData): HttpParams {
    let httpParams = new HttpParams();
    formData.forEach((value, key) => {
      httpParams = httpParams.append(key, String(value));
    });
    return httpParams;
  }

}
