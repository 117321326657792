<div class="main-content">
  <div class="header py-7 py-lg-5 pt-lg-3">
    <div class="container">
      <div class="header-body text-center mb-5">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-lg-6 col-md-8 px-5">
            <h1 class="text-white"></h1>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-2 pb-5">
    <div class="row justify-content-center">
      <div class="col-lg-12 col-md-7">
        <div class="card bg-secondary border-0 mb-0">
          <div class="card-header bg-transparent pb-5">
            <div class="btn-wrapper text-center">
              <img
                src="../../../assets/simex-img/logosimex.png"
                alt=""
                style="height: 100px"
              />
            </div>
          </div>
          <div class="row text-center">
            <div class="col-lg-12">
              <h2>REGISTRO</h2>
            </div>
          </div>
          <div class="card-body px-lg-5 py-lg-5">
            <div class="form-row">
              <div class="col-md-4 mb-3">
                <div class="form-group">
                  <label class="form-control-label" for="validationServer01">
                    Razón social
                  </label>

                  <input
                    class="form-control"
                    id="validationServer01"
                    placeholder="Razón social"
                    required=""
                    name="razonsocial"
                    type="text"
                    [(ngModel)]="cliente.razon_social"
                  />
                </div>
              </div>

              <div class="col-md-4 mb-3">
                <div class="form-group">
                  <label class="form-control-label" for="validationServer02">
                    Dirección
                  </label>

                  <input
                    class="form-control"
                    id="validationServer02"
                    placeholder="Dirección"
                    required=""
                    name="direccion"
                    type="text"
                    [(ngModel)]="cliente.direccion"
                  />
                </div>
              </div>

              <div class="col-md-4 mb-3">
                <div class="form-group">
                  <label
                    class="form-control-label"
                    for="validationServerUsername"
                  >
                    Dirección fiscal
                  </label>

                  <input
                    aria-describedby="inputGroupPrepend3"
                    class="form-control"
                    id="validationServerUsername"
                    placeholder="Dirección fiscal"
                    required=""
                    name="direccionfisica"
                    type="text"
                    [(ngModel)]="cliente.direccion_fiscal"
                  />
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="col-md-4 mb-3">
                <div class="form-group">
                  <label class="form-control-label" for="validationServer01">
                    Regimen fiscal
                  </label>

                  <input
                    class="form-control"
                    id="validationServer01"
                    placeholder="Regimen fiscal"
                    required=""
                    name="regimenfiscal"
                    type="text"
                    [(ngModel)]="cliente.regimen_fiscal"
                  />
                </div>
              </div>

              <div class="col-md-4 mb-3">
                <div class="form-group">
                  <label class="form-control-label" for="validationServer02">
                    Permiso Comisión Reguladora de Energía
                  </label>

                  <input
                    class="form-control"
                    id="validationServer02"
                    placeholder="Permiso Comisión Reguladora de Energía"
                    required=""
                    name="pcre"
                    type="text"
                    [(ngModel)]="cliente.permiso_comision_regulatoria_energia"
                  />
                </div>
              </div>

              <div class="col-md-4 mb-3">
                <div class="form-group">
                  <label
                    class="form-control-label"
                    for="validationServerUsername"
                  >
                    Teléfono
                  </label>

                  <input
                    aria-describedby="inputGroupPrepend3"
                    class="form-control"
                    id="validationServerUsername"
                    placeholder="Teléfono"
                    required=""
                    name="telefono"
                    type="text"
                    [(ngModel)]="cliente.telefono"
                    pattern="[0-9]*"
                  />
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="col-md-4 mb-3">
                <div class="form-group">
                  <label class="form-control-label" for="validationServer01">
                    Correo eléctronico
                  </label>

                  <input
                    class="form-control"
                    id="validationServer01"
                    placeholder="Representante legal"
                    required=""
                    name="correoelectronico"
                    type="email"
                    [(ngModel)]="cliente.email"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                  />
                  <span style="color: red" *ngIf="showInvalidEmail"
                    >Formato de correo invalido</span
                  >
                </div>
              </div>

              <div class="col-md-4 mb-3">
                <div class="form-group">
                  <label class="form-control-label" for="validationServer01">
                    Representante legal
                  </label>

                  <input
                    class="form-control"
                    id="validationServer01"
                    placeholder="Representante legal"
                    required=""
                    name="representantelegar"
                    type="text"
                    [(ngModel)]="cliente.representante_legal"
                  />
                </div>
              </div>

              <div class="col-md-4 mb-3">
                <div class="form-group">
                  <label class="form-control-label" for="validationServer02">
                    Responsable de la actividad
                  </label>

                  <input
                    class="form-control"
                    id="validationServer02"
                    placeholder="Responsable de la actividad"
                    required=""
                    name="responsable_actividad"
                    type="text"
                    [(ngModel)]="cliente.responsable_actividad"
                  />
                </div>
              </div>
              <div class="col-md-4 mb-3">
                <div class="form-group">
                  <label class="form-control-label" for="validationServer02">
                    RFC
                  </label>

                  <input
                    class="form-control"
                    id="validationServer02"
                    placeholder="RFC"
                    required=""
                    name="RFC"
                    type="text"
                    [(ngModel)]="cliente.rfc"
                  />
                </div>
              </div>
            </div>
            <div class="text-center">
              <button class="btn" (click)="goToLogin()">Volver</button>
              <button
                type="button"
                class="btn btn-success my-4"
                *ngIf="showRegisterButton()"
                (click)="registrar()"
              >
                Registrar
              </button>
              <button
                type="button"
                class="btn btn-success my-4"
                *ngIf="!showRegisterButton()"
                disabled
              >
                Registrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
