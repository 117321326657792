import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import swal from "sweetalert2";
import { Registro } from "src/app/models/registro";

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientesService } from "src/app/services/clientes.service";
import { Cliente } from "src/app/models/Cliente";
@Component({
  selector: "app-registrar",
  templateUrl: "registrar.component.html"
})
export class RegistrarComponent implements OnInit {
  focus;
  focus1;
  cliente:Cliente = new Cliente()
  showInvalidEmail:boolean = false

  numericoForm: FormGroup;
  constructor(private router: Router, private fb: FormBuilder, private clienteService:ClientesService) {

    this.numericoForm = this.fb.group({
      campoNumerico: ['', [Validators.pattern('[0-9]*')]],
    });
  }

  ngOnInit() {

  }

  goToLogin(){
    this.router.navigate(['/login']);
  }

  swalNotification(titile:string,text:string,icon:any){
    swal.fire({
      title: titile,
      text: text,
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  showRegisterButton():boolean{
    if(this.cliente.razon_social.trim()==""){
      return false
    }else if(this.cliente.direccion.trim()==""){
      return false
    }else if(this.cliente.direccion_fiscal.trim()==""){
      return false
    }else if(this.cliente.regimen_fiscal.trim()==""){
      return false
    }else if(this.cliente.permiso_comision_regulatoria_energia.trim()==""){
      return false
    }else if(this.cliente.telefono.trim()==""){
      return false
    }else if(this.cliente.email.trim()==""){
      return false
    }else if(this.cliente.representante_legal.trim()==""){
      return false
    }else if(this.cliente.responsable_actividad.trim()==""){
      return false
    }else if(this.cliente.rfc.trim()==""){
      return false
    }else{
      return true
    }
  }

  validarCorreoElectronico(correo) {
    const patronCorreo = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return patronCorreo.test(correo);
  }

  registrar(){
    this.showInvalidEmail = false
    if(this.validarCorreoElectronico(this.cliente.email)){
      this.save()
    }else{
      this.swalNotification("El formato del correo no es valido","","warning")
      this.cliente.email = ""
      this.showInvalidEmail = true
    }
  }

  save(){
    this.clienteService.registrar(this.cliente).subscribe(success=>{
      this.swalNotification("El registro se completó correctamente.","Recibiras tu clave de acceso al correo que nos proporcionaste "+this.cliente.email,"success")
      this.router.navigate(['/login']);
    },error=>{
      console.log(error);
      this.swalNotification("Error al completar registro.","Vuelve a intentarlo más tarde","error")
    })
  }

}
