import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { UsuariosService } from '../services/Usuarios.service';
import { Authenticate } from '../models/Authenticate';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: UsuariosService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

      return new Promise<boolean>((resolve, reject) => {
        // Verifica si el usuario está autenticado
        if(this.authService.getToken()!=null){
          this.authService.isTokenExpired().subscribe(isExpired=>{
            if(isExpired){
              this.router.navigate(['/login']);
              resolve(false)
            }else{
              resolve(true)
            }
          },error=>{
            console.log("token expirado")
            this.router.navigate(['/login']);
            resolve(false)
            reject(error)
          })

          resolve(true)
        }else{
          this.router.navigate(['/login']);
          resolve(false)
        }
      })
    }
}
