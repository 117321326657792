<div class=" main-content">
  <div class=" header py-7 py-lg-5 pt-lg-3">
    <div class=" container">
      <div class=" header-body text-center mb-5">
        <div class=" row justify-content-center">
          <div class=" col-xl-5 col-lg-6 col-md-8 px-5">
            <h1 class=" text-white"></h1>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class=" container mt-2 pb-5">
    <div class=" row justify-content-center">
      <div class=" col-lg-5 col-md-7">
        <div class=" card bg-secondary border-0 mb-0">
          <div class=" card-header bg-transparent pb-5">

            <div class=" btn-wrapper text-center">
              <img src="../../../assets/simex-img/logosimex.png" alt="" style="height:100px;">
            </div>
          </div>

          <div class=" card-body px-lg-5 py-lg-5">

            <form role="form">

              <div class="form-group" [ngClass]="{ focused: focus1 === true }">
                <div class="input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="ni ni-lock-circle-open"></i
                    ></span>
                  </div>
                  <input
                    class="form-control"
                    placeholder="Clave de acceso"
                    type="text"
                    name="clave"
                    (focus)="focus1 = true"
                    (blur)="focus1 = false"
                    [(ngModel)]="authenticate.key"
                  />
                </div>
              </div>

              <div class="text-center">
                <button type="button" class="btn btn-primary my-4" (click)="login()">
                  Iniciar sesion
                </button>
                <!---->
                <button type="button" class="btn btn-secondary my-4" (click)="goToRegistrar()">
                  Registrar
                </button>

              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
