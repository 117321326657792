import { Component, NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";

import { AuthGuard } from './auth-services/auth.guard';
import { AuthGuardLogin } from "./auth-services/auth.guard.login";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { LoginComponent } from "./pages/login/login.component";
import { RegistrarComponent } from "./pages/registrar/registrar.component";
import { SolicitudesComponent } from "./pages/solicitudes/solicitudes.component";
import { SolicitudComponent } from "./pages/solicitudes/solicitud/solicitud.component";
import { NuevaSolicitudComponent } from "./pages/solicitudes/nueva-solicitud/nueva-solicitud.component";
import { SolicitudActividadComponent } from "./pages/solicitudes/solicitud-actividad/solicitud-actividad.component";
const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full"
  },
  {
    path: "login",
    component:LoginComponent,
    canActivate:[AuthGuardLogin]
  },
  {
    path: "registrar",
    component:RegistrarComponent,
  },
  {
    path: "simex",
    component: AdminLayoutComponent,
    canActivate:[AuthGuard],
    children: [
      {
        path:'solicitudes',
        component: SolicitudesComponent
      },
      {
        path:'solicitudes/solicitud/:folio',
        component:SolicitudComponent
      },
      {
        path:'solicitudes/nueva-solicitud',
        component:NuevaSolicitudComponent
      },
      {
        path:'solicitudes/actividad/:folio/:id',
        component:SolicitudActividadComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
