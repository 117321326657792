import { Component, OnInit } from "@angular/core";
import { Authenticate } from 'src/app/models/Authenticate';
import { UsuariosService } from "src/app/services/Usuarios.service";
import { Router } from '@angular/router';
import swal from "sweetalert2";
@Component({
  selector: "app-login",
  templateUrl: "login.component.html"
})
export class LoginComponent implements OnInit {
  focus;
  focus1;

  authenticate:Authenticate = new Authenticate()
  constructor(private usuarioService:UsuariosService, private router: Router) {}

  ngOnInit() {

  }

  login(){
    this.usuarioService.login(this.authenticate).subscribe(success=>{
      localStorage.setItem("token", success["jwtToken"])
      localStorage.setItem("roles", JSON.stringify(success["authorities"]))
      localStorage.setItem("razon_social", success["razon_social"])
      this.router.navigate(['/simex/solicitudes']);

    },error=>{
      console.log(error)
      if(error["status"] != undefined && error["status"] == "401"){
        let msj =  error.error["mensaje"]==="Bad credentials"?
        "La clave de acceso es incorrecta":
        "favor de contactar al administrador"
        this.alertInactivo(msj)
      }else{
        this.alert()
      }
    })
  }

  alertInactivo(msj:string){
    swal.fire({
      title: "",
      text: msj,
      icon: "warning",
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  alert(){
    swal.fire({
      title: "Error al iniciar sesion.",
      text: "",
      icon: "warning",
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  successSwal() {
    swal.fire({
      title: "Se incio sesion correctamente",
      icon: "success",
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-success"
      }
    });
  }

  goToRegistrar(){
    this.router.navigate(['/registrar']);
  }

}
