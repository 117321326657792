import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SolicitudService } from "src/app/services/solicitud.service";
import swal from "sweetalert2";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Solicitud } from "src/app/models/Solicitud";
import * as $ from 'jquery';
import { DocumentoTemplateSolicitud } from "src/app/models/DocumentoTemplateSolicitud";
import { DocumentoSolicitado } from "src/app/models/DocumentoSolicitado";
import { SolicitudServicio } from "src/app/models/SolicitudServicio";
import { HistoricoFacturas } from "src/app/models/HistoricoFacturas";
import { Actividad } from "src/app/models/Actividad";

@Component({
  selector: "app-solicitud",
  templateUrl: "solicitud.component.html"
})
export class SolicitudComponent implements OnInit {
  folio:string
  solicitud:Solicitud = new Solicitud();
  avanzarReq:any = {
    solicitud_id:0,
    reqRegresarEstatus:false,
    comentarios_solicitud:null
  }
  editarPermission:boolean = false

  documentoTemplateNombre:string = ""
  documentoSolicitado:DocumentoSolicitado = new DocumentoSolicitado()
  comentarioDocCliente:string = ""
  nombreDocumentoSolicitado:string = ""

  comentariosDocSolicitado:string = ""
  isNotFinalizado:boolean = true


  lstHistoricoFacturas: HistoricoFacturas[] = []
  lstActividad: Actividad[] = []
  actividadSelected:Actividad = new Actividad()
  constructor(
    private solicitudService:SolicitudService,
    private route: ActivatedRoute,
    private router: Router) {}

  ngOnInit() {
    this.folio = this.route.snapshot.paramMap.get('folio')
    this.findByFolio()
    this.findAllActividad()
  }

  findByFolio(){
    this.solicitudService.findByFolio(this.folio).subscribe(success=>{
      this.solicitud = success
      if(this.solicitud.estatus_solicitud.clave == 'FINALIZADO'){
        this.isNotFinalizado = false
      }
    },error=>{
      console.log(error)
      this.sweetAlert("error","Error al llamar servicios", "btn-warning","Favor de llamar al administrador")
    })
  }

  refresh(){
    this.findByFolio()
    this.findAllActividad()
  }

  sweetAlert(icon,title:string,button:string,text:string){
    swal.fire({
      title: title,
      text: text,
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  getEstatusClass(estatus:string):string{
    if(estatus=="PENDIENTE"){
      return "bg-warning"
    }else if(estatus=="SOLICITUD DE INFORMACION"){
      return "bg-info"
    }else if (estatus=="EN REVISION") {
      return "bg-warning"
    }else if (estatus=="EN PROCESO") {
      return "bg-success"
    }else if (estatus=="FINALIZADO") {
      return "bg-danger"
    }else if(estatus=="SIN APROBAR"){
      return "bg-none"
    }
  }

  swalNotification(titile:string,text:string,icon:any){
    swal.fire({
      title: titile,
      text: text,
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  downloadDocumentoTemplate(item:DocumentoTemplateSolicitud){
    this.solicitudService.downloadDocumentoTemplate(item.id).subscribe(success=>{
        // Crear un Blob a partir del ByteArray
        const blob = new Blob([success], { type: item.content_type_template });
        // Crear una URL Blob a partir del Blob
        const blobUrl = URL.createObjectURL(blob);
       // Crea un enlace y simula el clic para descargar el archivo
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = item.file_name_template;
        a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  uploadDocumentoTemplateCliente($event, id:number){
    const formData = new FormData();
    formData.append("file",$event.target.files[0])
    formData.append("id",String(id))
    this.solicitudService.uploadDocumentoTemplateCliente(formData).subscribe(success=>{
      $("#inputPlantillaCliente").val("")
      this.findByFolio()
      this.swalNotification("Excelente!!","Se cargo el documento correctamente","success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  downloadDocumentoTemplateCliente(item:DocumentoTemplateSolicitud){
    this.solicitudService.downloadDocumentoTemplateCliente(item.id).subscribe(success=>{
      // Crear un Blob a partir del ByteArray
      const blob = new Blob([success], { type: item.content_type_cliente });
      // Crear una URL Blob a partir del Blob
      const blobUrl = URL.createObjectURL(blob);
     // Crea un enlace y simula el clic para descargar el archivo
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = item.file_name_cliente;
      a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  uploadDocumentoSolicitado($event, id:number){
    const formData = new FormData();
    formData.append("file",$event.target.files[0])
    formData.append("id",String(id))
    this.solicitudService.uploadDocumentoSolicitado(formData).subscribe(success=>{
      $("#inputDocCliente").val("")
      this.findByFolio()
      this.swalNotification("Excelente!!","Se cargo el documento correctamente","success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }


  downloadDocumentoSolicitado(item:DocumentoSolicitado){
    this.solicitudService.downloadDocumentoSolicitado(item.id).subscribe(success=>{
      // Crear un Blob a partir del ByteArray
      const blob = new Blob([success], { type: item.content_type });
      // Crear una URL Blob a partir del Blob
      const blobUrl = URL.createObjectURL(blob);
     // Crea un enlace y simula el clic para descargar el archivo
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = item.file_name;
      a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  uploadFichaPago($event){
    const formData = new FormData()
    formData.append("file",$event.target.files[0])
    formData.append("folio",this.solicitud.folio)
    this.solicitudService.uploadFichaPago(formData).subscribe(success=>{
      this.findByFolio()
      this.swalNotification("Excelente!!","Se cargo el comprobante de pago correctamente","success")
    },error=>{
      console.log(error)
      this.sweetAlert("error","Error al llamar servicios", "btn-warning","Favor de llamar al administrador")
    })
  }

  downloadFichaPago(){
    this.solicitudService.downloadFichaPago(this.solicitud.comprobante_pago.id).subscribe(success=>{
        // Crear un Blob a partir del ByteArray
        const blob = new Blob([success], { type: this.solicitud.comprobante_pago.content_type});
        // Crear una URL Blob a partir del Blob
        const blobUrl = URL.createObjectURL(blob);
       // Crea un enlace y simula el clic para descargar el archivo
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = this.solicitud.comprobante_pago.file_name;
        a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  findAllActividad(){
    this.solicitudService.findAllActividad(this.folio).subscribe(success=>{
      console.log(success)
      this.lstActividad = success
    },error=>{
      console.log(error)
      this.sweetAlert("error","Error al llamar servicios", "btn-warning","Favor de llamar al administrador")
    })
  }

  getEstatusActividadClass(estatus:string):string{
    if(estatus=="Pendiente"){
      return "bg-warning"
    }else if (estatus=="En proceso") {
      return "bg-success"
    }else if (estatus=="Finalizado"||estatus=="Cancelado") {
      return "bg-danger"
    }
  }

  goToActividad(id:number){
    this.router.navigate([`/simex/solicitudes/actividad/${this.folio}/${id}`]);
  }
}
