<div class="header pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links breadcrumb-light">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)"> <i class="fas fa-home"> </i> </a>
              </li>

              <li class="breadcrumb-item">
                <a routerLink="/simex/solicitudes"> Solicitudes </a>
              </li>
              <li class="breadcrumb-item" (click)="goToSolicitud()">
                <a href="javascript:void(0)"> {{ folio }} </a>
              </li>
              <li class="breadcrumb-item">
                <a> {{ actividad.factura.titulo }} </a>
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <a
            class="btn btn-sm btn-danger text-white"
            (click)="goToSolicitud()"
            style="cursor: pointer"
          >
            Regresar
          </a>

          <!--
          <a class=" btn btn-sm btn-neutral" href="javascript:void(0)">
            Filters
          </a>
         -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--6">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <div class="row">
            <div class="col-lg-6">
              <h6 class="text-muted text-uppercase ls-1 mb-1">
                Detalles de Solicitud {{ folio }}
              </h6>
              <h2 class="h3 mb-0">Actividad {{ actividad.factura.titulo }}</h2>
            </div>
            <div class="col-lg-6 text-right">
              <button class="btn btn-link" (click)="refresh()">
                <i class="fa fa-recycle" aria-hidden="true"></i>
                Refrescar
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div>
            <h4>Servicios</h4>
            <div class="table-responsive">
              <table class="table align-items-center table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Seccion</th>
                    <th>Servicio</th>
                    <th>Estatus</th>
                    <th>Documento finalización</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let ser of actividad.factura.servicios_factura_mensual
                    "
                  >
                    <td>{{ ser.seccion }}</td>
                    <td>{{ ser.servicio }}</td>
                    <td>
                      <span class="badge badge-dot mr-4">
                        <i [class]="getEstatusClass(ser.estatus) + ' bg-dot'">
                        </i>
                        <span class="status"> {{ ser.estatus }} </span>
                      </span>
                    </td>
                    <td>
                      <div *ngIf="ser.documento_finalizacion != null">
                        <div *ngIf="ser.vistaDocumentoAprobado">
                          <button
                            class="btn btn-success"
                            type="button"
                            (click)="downloadDocumentoFinalizacion(ser)"
                          >
                            <i class="fas fa-file-download"></i>
                            Descargar documento de finalización
                          </button>
                        </div>
                        <div *ngIf="!ser.vistaDocumentoAprobado">
                          <strong class="text-wrap">{{
                            ser.comentarioNoVista == null
                              ? "Visualización de documento no aprobada"
                              : ser.comentarioNoVista
                          }}</strong>
                        </div>
                      </div>
                      <span *ngIf="ser.documento_finalizacion == null"
                        >Sin documento</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h4>Facturas</h4>
            <div class="table-responsive">
              <table class="table align-items-center table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Nombre</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let fac of actividad.factura.documentos_factura_mensual
                    "
                  >
                    <td>{{ fac.file_name }}</td>
                    <td>
                      <button
                        class="btn btn-success"
                        type="button"
                        (click)="downloadFacturaMensual(fac)"
                      >
                        <i class="fas fa-file-download"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h4>Comprobantes de pago</h4>
            <input
              #filecomprobanteservicion
              type="file"
              class="form-control"
              name="file-comprobante-servicion"
              id="file-comprobante-servicion"
              (change)="subirComprobanteActividad($event)"
              hidden
            />
            <button
              class="btn btn-secondary"
              (click)="filecomprobanteservicion.click()"
            >
              Cargar comprobante de pago &nbsp;
              <i class="fas fa-file-upload"> </i>
            </button>
            <div class="table-responsive">
              <table class="table align-items-center table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Nombre</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let com of actividad.factura.comprobantes_pago_mensual
                    "
                  >
                    <td>{{ com.file_name }}</td>
                    <td>
                      <button
                        class="btn btn-success"
                        type="button"
                        (click)="downloadComprobantePago(com)"
                      >
                        <i class="fas fa-file-download"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--             MODALES              -->
  <div>
    <!-- -->

    <ng-template #modalVerActividad>
      <div class="modal-header">
        <h6 class="modal-title" id="modal-title-default">Ver actividad</h6>

        <button
          aria-label="Close"
          class="close"
          data-dismiss="modal"
          type="button"
          (click)="verActividadsModal.hide()"
        >
          <span aria-hidden="true"> × </span>
        </button>
      </div>

      <div class="modal-body">
        <h4>Servicios</h4>
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr>
                <th>Seccion</th>
                <th>Servicio</th>
                <th>Estatus</th>
                <th>Documento finalizado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let ser of actividad.factura.servicios_factura_mensual"
              >
                <td>{{ ser.seccion }}</td>
                <td>{{ ser.servicio }}</td>
                <td>{{ ser.estatus }}</td>
                <td></td>
                <td>
                  <div class="text-right">
                    <div class="dropdown no-caret" dropdown [dropup]="'true'">
                      <a
                        aria-controls="dropdown-basic"
                        class="btn btn-sm btn-icon-only text-light dropdown-toggle"
                        dropdownToggle
                        href="javascript:void(0)"
                        role="button"
                      >
                        <i class="fas fa-ellipsis-v"> </i>
                      </a>

                      <div
                        class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                        *dropdownMenu
                      >
                        <a href="javascript:void(0)" class="dropdown-item">
                          Aprobar visualización de documento
                        </a>
                        <a href="javascript:void(0)" class="dropdown-item">
                          Agregar observación
                        </a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>Facturas</h4>
        <input
          #filefacturaservicion
          type="file"
          class="form-control"
          name="file-factura-servicion"
          id="file-factura-servicion"
          (change)="closeverActividadsModal(); subirFacturaActividad($event)"
          hidden
        />
        <button
          class="btn btn-secondary"
          (click)="filefacturaservicion.click()"
        >
          Cargar documento &nbsp;
          <i class="fas fa-file-upload"> </i>
        </button>
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr>
                <th>Nombre</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let fac of actividad.factura.documentos_factura_mensual"
              >
                <td>{{ fac.file_name }}</td>
                <td>
                  <button
                    class="btn btn-success"
                    type="button"
                    (click)="downloadFacturaMensual(fac)"
                  >
                    <i class="fas fa-file-download"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>Comprobantes de pago</h4>
        <input
          #filecomprobanteservicion
          type="file"
          class="form-control"
          name="file-comprobante-servicion"
          id="file-comprobante-servicion"
          (change)="
            closeverActividadsModal(); subirComprobanteActividad($event)
          "
          hidden
        />
        <button
          class="btn btn-secondary"
          (click)="filecomprobanteservicion.click()"
        >
          Cargar comprobante de pago &nbsp;
          <i class="fas fa-file-upload"> </i>
        </button>
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr>
                <th>Nombre</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let com of actividad.factura.comprobantes_pago_mensual"
              >
                <td>{{ com.file_name }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="modal-footer">
        <button
          class="btn btn-link"
          data-dismiss="modal"
          type="button"
          (click)="verActividadsModal.hide()"
        >
          Cerrar
        </button>
      </div>
    </ng-template>
  </div>
  <style>
    .bg-dot {
      height: 1rem;
      width: 1rem;
    }

    .bg-none {
      background-color: black;
    }
  </style>
</div>
