import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Authenticate } from 'src/app/models/Authenticate';
@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  url:string = environment.url
  constructor(private http: HttpClient) { }

  login(authenticate:Authenticate): Observable<any> {
    return this.http.post<any>(`${this.url}/session/v1/authenticate`, authenticate);
  }

  public getToken(): string {
    return localStorage.getItem("token")
    // Obtiene el token del almacenamiento local o de la cookie según tus necesidades
  }

  public isTokenExpired(): Observable<boolean>{
    return this.http.get<boolean>(`${this.url}/session/v1/isTokenExpired/${this.getToken()}`)
  }

  public logout(): void {
    localStorage.removeItem("token")
    // Elimina el token del almacenamiento local o de la cookie según tus necesidades
  }

}
