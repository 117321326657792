import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Solicitud } from "src/app/models/Solicitud";
import { SolicitudService } from "src/app/services/solicitud.service";
import swal from "sweetalert2";
import * as $ from 'jquery';
import { ComprobantePagoSolicitud } from "src/app/models/ComprobantePagoSolicitud";
import { DocumentoCotizacion } from "src/app/models/DocumentosCotizacion";

@Component({
  selector: "app-solicitudes",
  templateUrl: "solicitudes.component.html"
})
export class SolicitudesComponent implements OnInit {

  solicitudes:Solicitud[] = []
  page:number = 1
  size:number = 5
  totalElements:number = 0
  filtro = {
    folio: '',
    cliente: '',
    estatus: '',
    cantidad: '',
    fecha: ''
  };
  constructor(private solicitudService:SolicitudService, private router: Router) {}

  ngOnInit() {

    var that = this
    /*
    $(document).ready(function() {
      // Seleccionamos el input por su ID
      var $miInput = $("#input-buscar-solicitud");

      // Agregamos el evento keypress al input
      $miInput.on("keypress", function(event) {
        // Comprobamos si la tecla presionada es "Enter" (código 13)
        if (event.which === 13) {
          // Aquí puedes realizar la acción que desees cuando se presione "Enter"
          that.searchData()
          // Evitamos el comportamiento por defecto del Enter en un formulario
          event.preventDefault();
        }
      });
    });
    */

    this.findAll()
  }

  findAll(){
    const formData = new FormData()
    formData.append("page",String(this.page-1))
    formData.append("size",String(this.size))
    this.solicitudService.findAll(formData).subscribe(success=>{
      this.solicitudes = success["content"]
      this.totalElements = success["totalElements"]
    },error=>{
      console.log(error)
      this.sweetAlert("error","Error al llamar servicios", "btn-warning","Favor de llamar al administrador")
    })
  }

  sweetAlert(icon,title:string,button:string,text:string){
    swal.fire({
      title: title,
      text: "",
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  swalNotification(titile:string,text:string,icon:any){
    swal.fire({
      title: titile,
      text: text,
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  getEstatusClass(estatus:string):string{
    if(estatus=="PENDIENTE"){
      return "bg-warning"
    }else if(estatus=="SOLICITUD DE INFORMACION"){
      return "bg-info"
    }else if (estatus=="EN REVISION") {
      return "bg-warning"
    }else if (estatus=="EN PROCESO") {
      return "bg-success"
    }else if (estatus=="FINALIZADO") {
      return "bg-danger"
    }else if(estatus=="SIN APROBAR"){
      return "bg-none"
    }
  }

  goToSolicitud(folio:string){
    this.router.navigate([`/simex/solicitudes/solicitud/${folio}`]);
  }

  searchData(){
    let search = String($("#input-buscar-solicitud").val())

    /*
    this.temp = this.solicitudes.filter((item) => {
      // Modifica esta lógica según tus necesidades
      return (
        item.folio.toUpperCase().includes(search.toUpperCase()) ||
        item.cliente.razon_social.toUpperCase().includes(search.toUpperCase()) ||
        item.estatus_solicitud.nombre.toUpperCase().includes(search.toUpperCase()) ||
        item.servicios.length.toString().includes(search)
      );
    });
    */
  }

  cotizacionAlert(folio:string,respuesta:string){
    var that = this
    swal.fire({
      title: `¿Estas seguro que deseas ${respuesta==='true'?'aceptar':'rechazar'} la cotización?`,
      showDenyButton: true,
      confirmButtonText: respuesta==='true'?'Aceptar':'Rechazar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        that.cotizacionService(folio, respuesta)
      }
    })
  }

  cotizacionService(folio:string, respuesta:string){
    const formData = new FormData()
    formData.append("folio",folio)
    formData.append("respuesta",respuesta)
    this.solicitudService.cotizacion(formData).subscribe(success=>{
      this.findAll()
      this.swalNotification(`${respuesta==='true'?'Excelente!!':''}`,`Se ${respuesta==='true'?'aceptó':'rechazó'} la cotización correctamente.`,"success")
    },error=>{
      console.log(error)
      this.sweetAlert("error","Error al llamar servicios", "btn-warning","Favor de llamar al administrador")
    })
  }

  uploadFichaPago($event, folio:string){
    const formData = new FormData()
    formData.append("file",$event.target.files[0])
    formData.append("folio",folio)
    this.solicitudService.uploadFichaPago(formData).subscribe(success=>{
      this.findAll()
      this.swalNotification("Excelente!!","Se cargo el comprobante de pago correctamente","success")
    },error=>{
      console.log(error)
      this.sweetAlert("error","Error al llamar servicios", "btn-warning","Favor de llamar al administrador")
    })
  }

  downloadFichaPago(item:ComprobantePagoSolicitud){
    this.solicitudService.downloadFichaPago(item.id).subscribe(success=>{
        // Crear un Blob a partir del ByteArray
        const blob = new Blob([success], { type: item.content_type});
        // Crear una URL Blob a partir del Blob
        const blobUrl = URL.createObjectURL(blob);
       // Crea un enlace y simula el clic para descargar el archivo
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = item.file_name;
        a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  pageChanged($event){
    this.page = $event["page"]
    this.findAll()
  }

  downloadCotizacion(item:DocumentoCotizacion){
    this.solicitudService.downloadCotizacion(item.id).subscribe(success=>{
      // Crear un Blob a partir del ByteArray
      const blob = new Blob([success], { type: item.content_type });
      // Crear una URL Blob a partir del Blob
      const blobUrl = URL.createObjectURL(blob);
     // Crea un enlace y simula el clic para descargar el archivo
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = item.file_name;
      a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }


  uploadCotizacionFirmada($event:any, folio:string){
    const formData = new FormData();
    formData.append("file",$event.target.files[0])
    formData.append("folio", folio)
    this.solicitudService.uploadCotizacionFirmada(formData).subscribe(success=>{
      this.findAll()
      this.swalNotification("Excelente!!",`Se cargo la cotización con folio ${folio}.`,"success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  downloadCotizacionFirmada(item:DocumentoCotizacion){
    this.solicitudService.downloadCotizacionFirmada(item.id).subscribe(success=>{
      // Crear un Blob a partir del ByteArray
      const blob = new Blob([success], { type: item.content_type });
      // Crear una URL Blob a partir del Blob
      const blobUrl = URL.createObjectURL(blob);
     // Crea un enlace y simula el clic para descargar el archivo
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = item.file_name;
      a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  downloadFactura(item:DocumentoCotizacion){
    this.solicitudService.downloadFactura(item.id).subscribe(success=>{
      // Crear un Blob a partir del ByteArray
      const blob = new Blob([success], { type: item.content_type });
      // Crear una URL Blob a partir del Blob
      const blobUrl = URL.createObjectURL(blob);
     // Crea un enlace y simula el clic para descargar el archivo
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = item.file_name;
      a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }
}
