<div class="header pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links breadcrumb-light">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)"> <i class="fas fa-home"> </i> </a>
              </li>

              <li class="breadcrumb-item">
                <a routerLink="/simex/solicitudes"> Solicitudes </a>
              </li>

              <li class="breadcrumb-item">
                <a> {{folio}} </a>
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <a
            class="btn btn-sm btn-danger text-white"
            routerLink="/simex/solicitudes"
            style="cursor: pointer"
          >
            Regresar
          </a>

          <!--
          <a class=" btn btn-sm btn-neutral" href="javascript:void(0)">
            Filters
          </a>
         -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--6">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <div class="row">
            <div class="col-lg-5 col-5 text-left m-2">
              <h2 class="h3 mb-0">Folio : {{ solicitud.folio }}</h2>
            </div>
            <div class="col-lg-6 col-5 text-right m-2">
              <!--
              <span class="badge badge-dot mr-4">
                <i
                  [class]="
                    getEstatusClass(solicitud.estatus_solicitud.clave) +
                    ' bg-dot'
                  "
                >
                </i>
                <span class="status">
                  {{ solicitud.estatus_solicitud.nombre }}
                </span>
              </span>
              -->
              <button class="btn btn-link" (click)="refresh()">
                <i class="fa fa-recycle" aria-hidden="true"></i>
                Refrescar</button>
            </div>
          </div>
        </div>
        <!--
        <div class="card-body">
          <div *ngIf="solicitud.cotizacion_aceptada && solicitud.cotizacion!=null && solicitud.comprobante_pago == null">
            <div>
              <h3>Recuerde subir su comprobante de pago</h3>
              <input
                type="file"
                name="comp_pago"
                id="comp_pago"
                #comppago
                (change)="uploadFichaPago($event)"
                hidden
              />
              <button class="btn btn-neutral" (click)="comppago.click()">
                <i class="ni ni-cloud-upload-96"></i>
                Subir comprobante de pago
              </button>
            </div>
          </div>
          <div *ngIf="solicitud.cotizacion_aceptada && solicitud.cotizacion!=null && solicitud.comprobante_pago != null">
            <button
            class="btn btn-success"
            (click)="downloadFichaPago()">
            <i class="ni ni-cloud-download-95"></i>
            Descargar comprobante de pago
          </button>
          </div>
        </div>
        -->
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <th>Sección</th>
              <th>Servicio</th>
              <!--<th>Estatus</th>-->
            </thead>
            <tbody>
              <tr *ngFor="let item of solicitud.servicios; let i = index">
                <td style="white-space: pre-line">{{ item.seccion.nombre }}</td>
                <td style="white-space: pre-line">
                  {{ item.servicio.nombre }}
                </td>
               <!--
                <td>
                  <span
                    class="badge badge-dot mr-4"
                    *ngIf="item.estatus == 'Pendiente'"
                  >
                    <i class="bg-warning"></i>
                    <span class="status"> Pendiente </span>
                  </span>
                  <span
                    class="badge badge-dot mr-4"
                    *ngIf="item.estatus == 'En proceso'"
                  >
                    <i class="bg-success"></i>
                    <span class="status"> En proceso </span>
                  </span>
                  <span
                    class="badge badge-dot mr-4"
                    *ngIf="item.estatus == 'Finalizado'"
                  >
                    <i class="bg-danger"></i>
                    <span class="status"> Finalizado </span>
                  </span>
                </td>
                -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="solicitud.documentosTemplateSolicitud.length > 0">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <div class="col-lg-5 col-5 text-left m-2">
            <!--<h6 class="text-muted text-uppercase ls-1 mb-1">Administración</h6>-->
            <h2 class="h3 mb-0">Documentos con plantilla</h2>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr>
                <th>Plantilla</th>
                <th>Subir documento</th>
                <th>Comentarios</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let item of solicitud.documentosTemplateSolicitud;
                  index as i
                "
              >
                <td>
                  <div class="input-group">
                    <input
                      id="nombre-plantilla"
                      type="text"
                      class="form-control"
                      disabled
                      [value]="item.nombre_documento"
                    />
                    <button
                      class="btn btn-success"
                      type="button"
                      (click)="downloadDocumentoTemplate(item)"
                    >
                      <i class="fas fa-file-download"></i>
                    </button>
                  </div>
                </td>
                <td>
                  <div class="custom-file" *ngIf="!item.cargo_cliente && isNotFinalizado">
                    <input
                      class="custom-file-input"
                      id="customFileLang inputPlantillaCliente"
                      lang="en"
                      type="file"
                      (change)="uploadDocumentoTemplateCliente($event, item.id)"
                    />
                    <label class="custom-file-label" for="customFileLang">
                      Seleccionar archivo
                    </label>
                  </div>

                  <div class="input-group" *ngIf="item.cargo_cliente">
                    <input
                      id="nombre-plantilla"
                      type="text"
                      class="form-control"
                      disabled
                      [value]="item.file_name_cliente"
                    />
                    <button
                      class="btn btn-success"
                      type="button"
                      (click)="downloadDocumentoTemplateCliente(item)"
                    >
                      <i class="fas fa-file-download"></i>
                    </button>
                  </div>
                </td>
                <td *ngIf="item.comentario != null && item.comentario != ''">
                  <textarea
                    class="form-control"
                    disabled
                    [value]="item.comentario"
                    cols="50"
                    rows="5"
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="solicitud.documentosSolicitados.length > 0">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <div class="col-lg-5 col-5 text-left m-2">
            <!--<h6 class="text-muted text-uppercase ls-1 mb-1">Administración</h6>-->
            <h2 class="h3 mb-0">Documentos</h2>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr>
                <th>Nombre documento</th>
                <th>Subir documento</th>
                <th>Comentarios</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let item of solicitud.documentosSolicitados; index as i"
              >
                <td>{{ item.nombre_documento }}</td>
                <td>
                  <div class="custom-file" *ngIf="!item.cargado && isNotFinalizado">
                    <input
                      class="custom-file-input"
                      id="customFileLang inputDocCliente"
                      lang="en"
                      type="file"
                      (change)="uploadDocumentoSolicitado($event, item.id)"
                    />
                    <label class="custom-file-label" for="customFileLang">
                      Seleccionar archivo
                    </label>
                  </div>

                  <div class="input-group" *ngIf="item.cargado">
                    <input
                      id="nombre-doc"
                      type="text"
                      class="form-control"
                      disabled
                      [value]="item.file_name"
                    />
                    <button
                      class="btn btn-success"
                      type="button"
                      (click)="downloadDocumentoSolicitado(item)"
                    >
                      <i class="fas fa-file-download"></i>
                    </button>
                  </div>
                </td>
                <td *ngIf="item.comentario != null && item.comentario != ''">
                  <textarea
                    class="form-control"
                    name="comentario_doc"
                    cols="30"
                    rows="5"
                    [value]="item.comentario"
                    disabled
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <div class="col-lg-5 col-5 text-left m-2">
            <h6 class="text-muted text-uppercase ls-1 mb-1">Administración</h6>
            <h2 class="h3 mb-0">Actividades</h2>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <th>Titulo</th>
              <th>estatus</th>
              <th>Fecha inicio</th>
              <th>Fecha fin</th>

              <th style="text-align: right">Acciones</th>
            </thead>
            <tbody>
              <tr *ngFor="let item of lstActividad; let i = index">
                <td style="white-space: pre-line">{{ item.factura.titulo }}</td>
                <td style="white-space: pre-line">

                  <span class="badge badge-dot mr-4">
                    <i
                      [class]="
                        getEstatusActividadClass(item.estatus) + ' bg-dot'
                      "
                    >
                    </i>
                    <span class="status"> {{ item.estatus }} </span>
                  </span>

                </td>
                <td style="white-space: pre-line">
                  {{ item.fecha_inicio }}
                </td>
                <td style="white-space: pre-line">
                  {{ item.fecha_fin == null ? "N/A" : item.fecha_fin }}
                </td>
                <td>
                  <div class="text-right">
                    <button class="btn btn-secondary" (click)="goToActividad(item.id)">
                      Ver
                    </button>
                    <!--
                    <div class="dropdown no-caret" dropdown [dropup]="'true'">
                      <a
                        aria-controls="dropdown-basic"
                        class="btn btn-sm btn-icon-only text-light dropdown-toggle"
                        dropdownToggle
                        href="javascript:void(0)"
                        role="button"
                      >
                        <i class="fas fa-ellipsis-v"> </i>
                      </a>

                      <div
                        class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                        *dropdownMenu
                      >
                        <a
                          href="javascript:void(0)"
                          class="dropdown-item"

                          (click)="goToActividad(item.id)"
                        >
                          Ver
                        </a>
                      </div>

                    </div>
                    -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="card-footer py-4"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .bg-dot {
    height: 1rem;
    width: 1rem;
  }
  .bg-none {
    background-color: black;
  }
</style>
