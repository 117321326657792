<div class="header pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links breadcrumb-light">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)"> <i class="fas fa-home"> </i> </a>
              </li>

              <li class="breadcrumb-item">
                <a href="javascript:void(0)"> Solicitudes </a>
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <a
            class="btn btn-sm btn-neutral"
            routerLink="/simex/solicitudes/nueva-solicitud"
            style="cursor: pointer"
          >
            Crear solicitud
          </a>
          <!--
          <a class=" btn btn-sm btn-neutral" href="javascript:void(0)">
            Filters
          </a>
         -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--6">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <div class="row">
            <div class="col-lg-6">
              <h6 class="text-muted text-uppercase ls-1 mb-1">
                Administración
              </h6>
              <h2 class="h3 mb-0">Solicitudes</h2>
            </div>
            <div class="col-lg-6 text-right">
              <button class="btn btn-link" (click)="findAll()">
                <i class="fa fa-recycle" aria-hidden="true"></i>
                Refrescar
              </button>
            </div>
          </div>
        </div>

        <div class="table-responsive" id="first-list">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr>
                <th scope="col">Folio</th>

                <!--<th scope="col">Estatus</th>-->

                <th scope="col">Fecha creación</th>

                <th scope="col">Fecha actualización</th>
                <th scope="col">Cotización</th>
                <th scope="col">Cotización firmada</th>
                <!--
                <th scope="col">Comprobante de pago</th>
                <th scope="col">Factura</th>
                -->
                <th scope="col">Acciones</th>
              </tr>
            </thead>

            <tbody class="list">
              <tr *ngFor="let item of solicitudes; index as i">
                <th scope="row">
                  <div class="media align-items-center">
                    <div class="media-body">
                      <span class="name mb-0 text-sm">
                        {{ item.folio }}
                      </span>
                    </div>
                  </div>
                </th>
                <!--
                <td class="budget">
                  <span class="badge badge-dot mr-4">
                    <i
                      [class]="
                        getEstatusClass(item.estatus_solicitud.clave) +
                        ' bg-dot'
                      "
                    >
                    </i>
                    <span class="status">
                      {{ item.estatus_solicitud.nombre }}
                    </span>
                  </span>
                </td>
                -->
                <td>
                  <span>
                    {{ item.fecha_creacion }}
                  </span>
                </td>

                <td>
                  <span>
                    {{ item.fecha_actualizacion }}
                  </span>
                </td>
                <td>
                  <div *ngIf="item.cotizacion !== null">
                    <!--
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        disabled
                        [value]="item.cotizacion"
                      />
                      <button
                        class="btn btn-success"
                        *ngIf="
                          item.cotizacion_aceptada === null &&
                          item.cotizacion_rechazada === null
                        "
                        (click)="cotizacionAlert(item.folio, 'true')"
                      >
                        Aceptar
                      </button>
                      <button
                        class="btn btn-danger"
                        *ngIf="
                          item.cotizacion_aceptada === null &&
                          item.cotizacion_rechazada === null
                        "
                        (click)="cotizacionAlert(item.folio, 'false')"
                      >
                        Rechazar
                      </button>
                    </div>
                    -->
                    <button
                      class="btn btn-success"
                      (click)="downloadCotizacion(item.cotizacion)"
                    >
                      <i class="ni ni-cloud-download-95"></i>
                      Descargar cotización
                    </button>
                  </div>
                  <strong *ngIf="item.cotizacion === null"
                    >Cotización pendiente</strong
                  >
                </td>
                <td>
                  <div *ngIf="item.cotizacion != null">
                    <span *ngIf="item.cotizacion_firmada == null">
                      <input
                        #filecotizacionfirmada
                        type="file"
                        class="form-control"
                        name="file-cotizacion"
                        id="file-cotizacion"
                        (change)="uploadCotizacionFirmada($event, item.folio)"
                        hidden
                      />
                      <button
                        class="btn btn-secondary"
                        (click)="filecotizacionfirmada.click()"
                      >
                        Subir cotización firmada &nbsp;
                        <i class="fas fa-file-upload"> </i>
                      </button>
                    </span>
                    <span *ngIf="item.cotizacion_firmada != null">
                      <button
                        class="btn btn-success"
                        (click)="
                          downloadCotizacionFirmada(item.cotizacion_firmada)
                        "
                      >
                        <i class="ni ni-cloud-download-95"></i>
                        Descargar cotización firmada
                      </button>
                    </span>
                  </div>
                </td>
                <!--
                <td>
                  <span
                    *ngIf="
                      item.comprobante_pago == null && item.cotizacion_aceptada
                    "
                  >
                    <input
                      type="file"
                      name="comp_pago"
                      id="comp_pago"
                      #comppago
                      (change)="uploadFichaPago($event, item.folio)"
                      hidden
                    />
                    <button class="btn btn-neutral" (click)="comppago.click()">
                      <i class="ni ni-cloud-upload-96"></i>
                      Subir comprobante de pago
                    </button>
                  </span>

                  <button
                    class="btn btn-success"
                    *ngIf="
                      item.comprobante_pago != null && item.cotizacion_aceptada
                    "
                    (click)="downloadFichaPago(item.comprobante_pago)"
                  >
                    <i class="ni ni-cloud-download-95"></i>
                    Descargar comprobante
                  </button>
                </td>
                <td>
                  <span *ngIf="item.factura != null">
                    <button
                      class="btn btn-success"
                      (click)="downloadFactura(item.factura)"
                    >
                      <i class="ni ni-cloud-download-95"></i>
                      Descargar factura
                    </button>
                  </span>
                  <strong *ngIf="item.factura == null">No hay factura disponible</strong>
                </td>
                -->
                <td>
                  <button
                    class="btn btn-secondary"
                    (click)="goToSolicitud(item.folio)"
                  >
                    Ir a solicitud
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer py-4">
          <nav aria-label="...">
            <pagination
              [totalItems]="totalElements"
              class="justify-content-end mb-0"
              [customPreviousTemplate]="prevTemplate"
              [customNextTemplate]="nextTemplate"
              [itemsPerPage]="size"
              [(ngModel)]="page"
              (pageChanged)="pageChanged($event)"
              [maxSize]="5"
            >
            </pagination>

            <ng-template #nextTemplate let-currentPage="currentPage">
              <ng-container *ngIf="!disabled">
                <i class="fas fa-angle-right"></i>
              </ng-container>
            </ng-template>

            <ng-template #prevTemplate let-currentPage="currentPage">
              <ng-container *ngIf="!disabled">
                <i class="fas fa-angle-left"></i>
              </ng-container>
            </ng-template>
          </nav>
        </div>
        <!--
        <ngx-datatable
          class="bootstrap selection-cell"
          [columnMode]="'force'"
          [headerHeight]="50"
          [footerHeight]="50"
          [rowHeight]="'auto'"
          [limit]="10"
          [rows]="temp"
        >
        <ngx-datatable-column name="Folio" prop="folio"></ngx-datatable-column>
          <ngx-datatable-column name="Cliente" prop="cliente.razon_social"></ngx-datatable-column>
          <ngx-datatable-column name="Estatus" prop="estatus_solicitud.clave">
            <ng-template ngx-datatable-cell-template let-row="row">

              <span class=" badge badge-dot mr-4">
                <i [class]="getEstatusClass(row.estatus_solicitud.clave)+' bg-dot'"> </i>
                <span class=" status"> {{row.estatus_solicitud.nombre}} </span>
              </span>

            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Cantidad de servicios" prop="servicios.length">
            <ng-template ngx-datatable-cell-template let-row="row">
              <span class="text-center">
                {{row.servicios.length}}
              </span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Fecha creación" prop="fecha_creacion"></ngx-datatable-column>
          <ngx-datatable-column name="Fecha actualización" prop="fecha_actualizacion"></ngx-datatable-column>
          <ngx-datatable-column name="Acciones">
            <ng-template ngx-datatable-cell-template let-row="row">
              <button class="btn btn-secondary" (click)="goToSolicitud(row.id)">
                Ir a solicitud
              </button>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
-->
      </div>
    </div>
  </div>
</div>
<style>
  .bg-dot {
    height: 1rem;
    width: 1rem;
  }
  .bg-none {
    background-color: black;
  }
</style>
