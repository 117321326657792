import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from '../../services/spinner.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private spinnerService:SpinnerService){}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinnerService.show();
    // Obtén el token de autenticación desde donde lo tengas almacenado
    const token = localStorage.getItem("token");
    // Agrega el token al encabezado de autorización de la solicitud
    if(!request.url.includes("/session/v1/authenticate") && !request.url.includes("/registrar/v1/save")){
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }


    return next.handle(request).pipe(
      finalize(()=> this.spinnerService.hide())
    );
  }
}
